import jQuery from 'jquery';
window.$ = window.jQuery;

// import moment from 'moment';

import moment from 'moment-timezone';

import './strtotime.js';
// import './format_date.js';

var event_popup = undefined;

var mouseX = 0, mouseY = 0;

var popover_data = undefined;

var currentTarget = null;

var grid = null;


jQuery( function( $ )
{

    // TODO : Month Navigation

    $('[name=month-nav]').on( 'change', function( e ) {
        e.preventDefault();

        var date = $('[name=month-nav]').val();
        var url = $( e.currentTarget ).data( 'url' )+'&date='+date;

        window.location.href = url;

    });


    $('.filter-switch').on( 'click', function ( e )
    {
        $( "#filter-bar" ).toggle( "0.3s" ).css("display", "flex");
    } );

    $('.scheduled-event-bar-button').on( 'click', function ( e )
    {
        $( e.currentTarget ).toggleClass( "scheduled-event-bar-button-open" )

        var ev_id = $( e.currentTarget ).data( 'evid' );

        $( "#evd-"+ev_id ).toggleClass( 'scheduled-event-show' );

        // $( "#filter-bar" ).toggle( "0.3s" ).css("display", "flex");
    } );

    // $('.scheduled-event-bar-button-open').on( 'click', function ( e )
    // {
    //     $('.scheduled-event-bar-button-open').removeClass( "scheduled-event-bar-button-open".addClass( "scheduled-event-bar-button-closed" ) );
    //     console.log( 'toggle off', e );
    //     // $( "#filter-bar" ).toggle( "0.3s" ).css("display", "flex");
    // } );

    $('#clear-filters').on( 'click', function( e )
    {
        $('#field-course').val('-1');
        $('#field-trainer').val('-1');
        $('#field-admin').val('-1');

        $('#filter-events').trigger( "click" );
    } );

    $('.grid-day').on( 'click', function ( e )
    {

        let id = $( e.currentTarget ).data( 'selected' );

        // console.log( id );

        $( '.selected').removeClass( 'selected' );

        $( "#"+id ).addClass( "selected" );

    } );

    // $('body').on( 'mousemove', function( e )
    // {
    //     mouseX = e.pageX;
    //     mouseY = e.pageY;

    // } );

    $('.draggable').on( 'dblclick', function ( e )
    {

        e.preventDefault();

        popover_data = $( this ).data( 'popover' );

        if ( event_popup != undefined )
        {
            clearTimeout( event_popup );

            $("#popover").css('display', 'none' );

            event_popup = undefined;
        }

        event_popup = undefined;

        window.location.href= '/event/'+popover_data.id ;

    } );


    $('.searched-event').on( 'dblclick', function ( e )
    {
        e.preventDefault();

        let event_id = $( this ).data( 'event_id' );

        window.location.href= '/event/'+event_id ;

    });

    $('.scheduled-event').on( 'dblclick', function ( e )
    {
        e.preventDefault();

        let event_id = $( this ).data( 'event_id' );

        window.location.href= '/event/'+event_id ;

    });

    $( '.draggable' ).on( 'mouseenter' , function ( e )
    {
        if ( event_popup != undefined )
            return;

        // console.log( "Mouse Enter" );

        grid = document.getElementById( "grid" );
        // console.table( e.currentTarget.getBoundingClientRect()) ;

        let offset = mouseX = $( e.currentTarget ).offset();
        // console.log( offset );

        mouseX = offset.left;
        mouseY = offset.top;

        currentTarget = e.currentTarget;

        // console.log( "Body", $( 'body' ).offset() );

        // console.log( "ScrollY", window.scrollY );

        popover_data = $( this ).data( 'popover' );

        event_popup = setTimeout( function( e ) {
            // display popover

            var x = mouseX ; //- 200;
            var y = mouseY ; //- 100;

            var left = mouseX ; //- 200;
            var top = mouseY; //- 100;
            var bottom = 'auto';
            var right = 'auto';

            var pWidth = $('#popover').width();
            var pHeight = $('#popover').height();

            var gr = grid.getBoundingClientRect();

            if ( ( x + pWidth ) > gr.right )
            {
                left = Math.ceil( gr.width - pWidth)+"px";

            }
            else
            {
                left = Math.ceil( x )+"px";
            }

            if ( y + pHeight > (window.innerHeight || document.documentElement.clientHeight ) )
            {
                // console.log ( "off bottom edge" );
                top = Math.ceil( y -  pHeight - 55)+"px";
            }
            else
            {
                top = Math.ceil( y+15 )+"px";
            }

            if ( y - window.scrollY < pHeight )
            {
                top = Math.ceil( y+15 )+"px";
            }

            // console.log( popover_data );

            let s_dt = new Date( popover_data.start_date * 1000 ); // convert from seconds to milliseconds
            let e_dt = new Date( popover_data.end_date * 1000 );

            let price = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(popover_data.price);

            $("#popover").html(
                "<h3>"+popover_data.title+"</h3>"+
                "<dl><dt>Alt Title</dt><dd>"+popover_data.alt_title+"</dd></dl>"+
                "<dl><dt>Places</dt><dd>"+popover_data.places+"</dd></dl>"+
                "<dl><dt>Price</dt><dd>"+price+"</dd></dl>"+
                "<dl><dt>Start</dt><dd>"+moment( s_dt ).tz( serverTimeZone ).format( 'MMMM Do YYYY, h:mm:ss' )+"</dd></dl>"+
                "<dl><dt>End</dt><dd>"+moment( e_dt ).tz( serverTimeZone ).format( 'MMMM Do YYYY, H:mm:ss' )+"</dd></dl>"+
                "<dl><dt>Trainer</dt><dd>"+popover_data.trainer+"</dd></dl>"+
                "<dl><dt>Venue</dt><dd>"+popover_data.venue+"</dd></dl>"+
                "<dl><dt>Sylabus</dt><dd>"+popover_data.sylabus+"</dd></dl>"
            );

            $("#popover").css('top', top );
            // $("#popover").css('right', right );
            // $("#popover").css('bottom', bottom );
            $("#popover").css('left', left );

            $("#popover").css('display', 'flex' );


        }, 1000 );

    });

    // $( '.draggable' ).on ('mouseleave', function ( e )
    // {
        // console.log( "-mo", e );

    //     if ( event_popup != undefined )
    //     {
    //         clearTimeout( event_popup );

    //         setTimeout( function () {
    //             $("#popover").css('display', 'none' );
    //             event_popup = undefined;
    //         }, 1000 );

    //     }

    //     // Clear popover
    // });

    $( '.draggable' ).on ('mouseleave', function ( e )
    {

        if ( event_popup != undefined )
        {
            // console.log( "Clearing the timeout" );

            clearTimeout( event_popup );

            setTimeout( function () {
                //Clear popover
                $("#popover").css('display', 'none' );
            }, 100 );

            event_popup = undefined;
        }

    });

    $("#add_new_course").on( "click", ( e ) => {
        e.preventDefault();

        console.log( $('.selected') );
        console.log( $('.selected').first() );
        
        var id = $('.selected').first().attr( 'id' );
        var href = $(e.currentTarget ).attr( 'href' );
       
        //console.log( href+"?date="+id );

        window.location.href=href+"?date="+id;

    });

});
