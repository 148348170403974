import jQuery from 'jquery';
window.$ = window.jQuery;

jQuery( function( $ )
{

    $( "#name").on( 'change', (e) => {
        
      var name = $( e.currentTarget ).val();
      var c_slug = $( "input[ name='slug']").val();

      if ( c_slug === "" )
      {
          var slug = name.toLowerCase()
                        .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                        .replace(/^-+|-+$/g, '');   // Remove leading and trailing hyphens
                        // console.log( name, slug );
        
          $( "input[ name='slug']").val( slug );
      }
      
    });

} );
