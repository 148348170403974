import jQuery from 'jquery';

import { first } from 'lodash';

window.$ = window.jQuery;

import 'jquery-ui/ui/widgets/sortable.js';
// jQuery.sortable = sortable;

jQuery( function( $ )
{

    // $(document).ready(function(){
    //   $('#venues-table tbody tr td').each(function(){
    //       $(this).css('width', $(this).width() +'px');
    //   });
    // });

    var fixHelper = function(e, ui) {  
      ui.children().each(function() {  
        $(this).width($(this).width());  
      });  
      return ui;  
    };

    console.log( $('#venues-table' ).length );

    if ( $('#venues-table' ).length )
    {
      
      jQuery.sortable( {
        cursor : "move",
        items : "> tr",
        axis: 'y',
        update: function(event, ui) {
          var items = $.sortable("serialize", "#venues-table tbody" ).items;

          if ( items.length > 1 )
          {
            var ids = items.map( (i, x) => {
                return jQuery( i.item[0] ).data("id");
            });

            var token = $('meta[name="csrf-token"]').attr('content');

            axios.post( route( 'venue.reorder' ), {
                _token: token,
                ids: ids
              })
              .then(function (response) {
                // console.log(response);
                // console.log( "Success: trainer was deleted");
                toastr.success( "Venues reordered");               
              })
              .catch(function (error) {
                console.log(error);
                toastr.error( "Venues not reordered");
              });
    
            // // console.log(event, ui );
            // console.log( items );
            // console.log( ids );
          }
        },
        helper : fixHelper,
      },  "#venues-table tbody");
    }

    // $( "#venues-table" ).on( "sortactivate", function( event, ui ) { console.log( event ); console.log( ui ); } );

    $( '.delete_venue' ).on( 'click' , function ( e ) {

        e.preventDefault();

        var vid = $( e.currentTarget ).data('id');
        // var uid = $( e.currentTarget ).data( 'uid' );
        
        // console.log( "delete trainer with id ", tid );

        var token = $('meta[name="csrf-token"]').attr('content');

        axios.post( route( 'venue.delete', { venue: vid } ), {
            _token: token,
          })
          .then(function (response) {
            // console.log(response);
            // console.log( "Success: trainer was deleted");
            toastr.success( "Venue was deleted");
            
            $( "#v"+vid ).remove();

          })
          .catch(function (error) {
            console.log(error);
            toastr.error( "Sylabus was not deleted");
          });
 
    });

});