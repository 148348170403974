import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import _ from 'lodash';
window._ = _;

import Quill from 'quill';
window.Quill = Quill;
window.Quill.import('formats/link').PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'telno'];

window.toastr = require('toastr');
window.toastr.options = {
  "progressBar": true
};

import sortable from'jquery-ui/ui/widgets/sortable.js';
jQuery.sortable = sortable;

// console.log( jQuery );
// console.log( sortable );

require ('./resumable/resumable.js');

// console.log( "Starting" );

// Comment out to enable diagnostic messages
// console.log = function() {};

// window.$ = window.jQuery = require('jquery');

// console.log( window.jQuery );

// console.log( jQuery );



// try {
//     window.$ = window.jQuery = require('jquery');

//     require('bootstrap');

// } catch (e) {}

import '../sass/app.scss';
import * as bootstrap from 'bootstrap';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import 'dotenv/config';

window.client_site_id = process.env.CLIENT_SITE_ID;

window.process = process;

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     wsHost: process.env.MIX_PUSHER_HOST, // process.env.MIX_PUSHER_HOST ?? `ws-${process.env.MIX_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: process.env.MIX_PUSHER_PORT ?? 80,
//     wssPort: process.env.MIX_PUSHER_PORT ?? 443,
//     forceTLS: (process.env.MIX_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws'] ,  //, 'wss'],
//     cluster:process.env.MIX_PUSHER_APP_CLUSTER,//added this line
// });
