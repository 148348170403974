import jQuery from 'jquery';

import { first } from 'lodash';

window.$ = window.jQuery;

// console.log( Echo.channel( 'home' ));

// Echo.channel( 'home' ).listen( '.NewMessage', (e) => {
//     console.log( e );
// });

// Echo.channel( 'home' ).listenToAll( (event, data) => {
//     console.log( event, data );
// });

const new_attendee_markup = `
    <tr class='command-row' data-lid="new">
        <td width="1%" ><input class="picker" id="picker-new" type='checkbox' name="sel[]" data-email="" data-cemail="" data-passed="" data-learner="" data-lid="new" style="padding-top:5px"/></td>
        <td data-lid="new" >
            <div style="width:100%; display:flex; flex-direction:row; justify-content:space-between">
                <input type="text" class="editable" style="padding:5px; width:24%" name="name-new" id="name-new" />
                <input type="text" class="editable" style="padding:5px; width:24%" name="pemail-new" id="pemail-new" />
                <input type="text" class="editable" style="padding:5px; width:24%" name="phone-new" id="phone-new" />
                <input type="text" class="editable" style="padding:5px; width:24%" name="cemail-new" id="cemail-new" />
            </div>
            <div style="margin-top:5px; width:100%; display:flex; flex-direction:row; justify-content:space-between; align-items:center;">
                <span style="padding:5px 5px 5px 0;">Notes:</span>
                <input class='editable' id="notes-new" name="notes-new" style="padding:5px; width:90%"/>
                <button id="pass-new" class="btn btn-info passAttendee" style="height: 35px; margin-left: 10px; display:none;" >Pass</button>
                <button id="certificate-new" class="btn btn-dark viewCertificate" style="height: 35px; margin-left: 10px; display:none;" >Certificate</button>
                <button class="btn btn-secondary save-button"  id="save-new" style="height: 35px; margin-left: 10px;">Save</button>
            </div>
            <div class="row-commands" >
                <a href="#" class='sendJI'>Send Joing Instructions</a>
                <span style="margin-left:20px;margin-right:20px;"> </span>
                <a href="#" class='viewCertificate'>View Certificate</a>
                <a href="#" class='deleteAttendee'>Delete</a>
                <a href="#" class='rescheduleAttendee'>Reschedule</a>
                <a href="#" class='passAttendee'>Mark Passed</a>
            </div>
        </td>
    </tr>
`;

var blockEditMode = false;

jQuery( function( $ )
{

    $( "#saveEvent" ).on( "click", function ( e ) {

        let dirty = $('.dirty' );

        let selectedProduct = $( "#product_id" ).val();

        if ( selectedProduct == -1 )
        {        
            if ( confirm( "No product has been selected\n\nSave anyway?" ) )
            {

            }
            else
                e.preventDefault();
        }

        if ( dirty.length > 0 )
        {        
            if ( confirm( "Some Student Fields Have Not Been Completed\n\nSave anyway?" ) )
            {

            }
            else
                e.preventDefault();
        }

    } );


    function update_field( e ) {

        const $this = $(this);

        let lid = $( e.currentTarget ).parent().parent().data( 'lid' );
        let field = $( e.currentTarget ).data( 'field' );
        let value = $( e.currentTarget ).text();
        var event_id = $('[name=id]').val();

        // console.log( "record", lid, field, "changed", $( e.currentTarget ).html() );

        //console.log( $this.data('before'), $this.html() );

        var lids = [];

        lids.push( lid );

        if ( blockEditMode )
        {
            console.log( 'block editing',$('.picker:checked') );
            $('.picker:checked').each( ( index, row ) => {
                let curLid = $(row).data( 'lid' );

                console.log( "update row", curLid, field)
                if ( curLid != lid )
                {
                    $( "#"+field+"-"+curLid ).html( value );

                    lids.push( curLid );
                }
                
            });
        }

        // console.log( "Lids", lids );

        // console.log( $this.data('before'), '==', $this.text() );

        if ($this.data('before') !== $this.text()) {
            $this.data('before', $this.text());

            e.preventDefault();
             
            $this.trigger('blur');
        
            var token = $('meta[name="csrf-token"]').attr('content');
        
            var url = "/event/attendee/field";

            console.log( "url", url );

            axios.post( url, {
                _token: token,
                value: value,
                field: field,
                lid: lids,
                course_id: event_id,
            })
            .then(function (response) {
                // console.log(response);
                console.log( "Success: field updated", response);
                
                console.log( response.data.lids );

                for ( let i = 0; i < lids.length; i++ )
                {
                    if ( lids[ i ] == 'new' )
                    {
                        $( e.currentTarget ).parent().parent().parent().attr( 'data-lid', response.data.lids[ i ] );
                        $( e.currentTarget ).parent().parent().parent().prop( 'data-lid', response.data.lids[ i ] );
                        $( e.currentTarget ).parent().parent().parent().data( 'lid', response.data.lids[ i ] );

                        $( e.currentTarget ).parent().parent().attr( 'data-lid', response.data.lids[ i ] );
                        $( e.currentTarget ).parent().parent().prop( 'data-lid', response.data.lids[ i ] );                        
                        $( e.currentTarget ).parent().parent().data( 'lid', response.data.lids[ i ] );

                        $( "#picker-"+lids[ i ] ).prop( "id", "#picker-"+response.data.lids[ i ] ).prop( "data-lid", response.data.lids[ i ] ).attr( "data-lid", response.data.lids[ i ] );
                    }
                }
            })
            .catch(function (error) {
                console.log( "Error:", error);
            });

        }
    
    }

    $(".save-button").on( 'click', update_fields );

    function update_fields( e ) {

        e.preventDefault();

        const $this = $(this);

        let $parent = $( e.currentTarget ).parent();
        let lid = $parent.parent().data( 'lid' );
        let field = $( e.currentTarget ).data( 'field' );
        let value = $( e.currentTarget ).text();
        var event_id = $('[name=id]').val();

        var name = $( '#name-'+lid ).val();
        var email = $('#pemail-'+lid).val();
        var phone = $('#phone-'+lid).val();
        var cemail = $('#cemail-'+lid).val();
        var notes = $('#notes-'+lid).val();

        // console.log( "record", lid, field, "changed", $( e.currentTarget ).html() );

        //console.log( $this.data('before'), $this.html() );

        var lids = [];

        lids.push( lid );

        if ( blockEditMode )
        {
            console.log( 'block editing',$('.picker:checked') );
            $('.picker:checked').each( ( index, row ) => {
                let curLid = $(row).data( 'lid' );

                console.log( "update row", curLid, field)
                if ( curLid != lid )
                {
                    $( "#cemail-"+curLid ).val( cemail );

                    lids.push( curLid );
                }
                
            });
        }

        console.log( name, email, phone, cemail, notes );

        // console.log( "Lids", lids );

        // console.log( $this.data('before'), '==', $this.text() );

        var token = $('meta[name="csrf-token"]').attr('content');

        var url = "/event/attendee/fields";

        // console.log( "url", url );

        axios.post( url, {
            _token: token,
            lid: lids,
            name: name,
            pemail: email,
            phone: phone,
            cemail:cemail,
            notes: notes,
            course_id: event_id,
        })
        .then(function (response) {
            // console.log(response);
            console.log( "Success: field updated", response);
            
            console.log( response.data );

            $( e.currentTarget ).parent().parent().parent().attr( 'data-lid', response.data.lid );
            $( e.currentTarget ).parent().parent().parent().prop( 'data-lid', response.data.lid );
            $( e.currentTarget ).parent().parent().parent().data( 'lid', response.data.lid );

            $( e.currentTarget ).parent().parent().attr( 'data-lid', response.data.lid );
            $( e.currentTarget ).parent().parent().prop( 'data-lid', response.data.lid );                        
            $( e.currentTarget ).parent().parent().data( 'lid', response.data.lid );

            $( '#name-new' ).prop( "id", "name-"+response.data.lid ).prop("name", "name-"+response.data.lid);
            $( '#pemail-new' ).prop( "id", "pemail-"+response.data.lid ).prop("name", "pemail-"+response.data.lid).removeClass('dirty');
            $( '#phone-new' ).prop( "id", "phone-"+response.data.lid ).prop("name", "phone-"+response.data.lid).removeClass('dirty');
            $( '#cemail-new' ).prop( "id", "cemail-"+response.data.lid ).prop("name", "cemail-"+response.data.lid).removeClass('dirty');
            $( '#notes-new' ).prop( "id", "notes-"+response.data.lid ).prop("name", "notes-"+response.data.lid).removeClass('dirty');
            $( '#save-new' ).prop( "id", "save-"+response.data.lid );
            $( '#pass-new' ).prop( "id", "pass-"+response.data.lid );
            $( '#certificate-new' ).prop( "id", "certificate-"+response.data.lid );

            $( '#name-'+response.data.lid ).removeClass('dirty');
            $( '#pemail-'+response.data.lid ).removeClass('dirty');
            $( '#phone-'+response.data.lid ).removeClass('dirty');
            $( '#cemail-'+response.data.lid ).removeClass('dirty');
            $( '#notes-'+response.data.lid ).removeClass('dirty');


            $( '#pass-'+response.data.lid ).show();

            $( '#save-'+response.data.lid ).removeClass("btn-danger");
            
            $( "#picker-new" ).prop( "id", "#picker-"+response.data.lid ).prop( "data-lid", response.data.lid ).attr( "data-lid", response.data.lid );
            
            // console.log( $( '#pass-'+response.data.lid ) );

            $( '#pass-'+response.data.lid ).off('click').on( "click", passAttendee );
        
        })
        .catch(function (error) {
            console.log( "Error:", error);
        });

    }
    
    function deleteAttendee( e )
    {
        e.preventDefault();
        
        let lid = $( e.currentTarget ).parent().parent().parent().data( 'lid' );
        
        let field = $( e.currentTarget ).data( 'field' );

        if ( lid === 'new' )
        {
            $( e.currentTarget ).parent().parent().parent().remove();
        }
        else
        {
            var yesno = confirm( "Are you Sure" );

            if ( yesno )
            {
                var url = "/event/attendee/"+lid;

                var token = $('meta[name="csrf-token"]').attr('content');

                axios.delete( url, {
                    _token: token,
                })
                .then(function (response) {

                    $( e.currentTarget ).parent().parent().parent().remove();

                    // console.log( "Success: ", response );
        
                })
                .catch(function (error) {
                    
                    console.log( "Error:", error);

                });

                // console.log( "Delete learner", lid  );
            }
        }
    }

    // $('#venue_select').on('change', function( e ) {
    

    // $( '.editable' ).on( "click", function ( e ) {
    //     let eid = $(this).data('editor' );

    //     $('#edit-'+eid ).text($(this).text()).show().focus();
    //     $(this).hide();
    // });

    $('#venue_select').on('change', function( e ) {
        
        // Get venue details and update the relevant fields

        let lid = $( e.currentTarget ).parent().parent().data( 'lid' );
    
        let venue_id = e.target.value;

        if ( venue_id == -1 )
        {
            $( "textarea[ name='address' ]" ).val( "" );
            $( "input[ name='postcode' ]" ).val( "" );
            $( "input[ name='contact_number' ]" ).val( "" );
            $( "input[ name='contact_email' ]" ).val( "" );

            // $( "textarea[ name='additional_info' ]" ).val( "" );

            $( "textarea[ name='resources' ]" ).val( "" );            

            $("#sce_check").hide();

        }
        else
        {

            var token = $('meta[name="csrf-token"]').attr('content');
        
            var url = "/event/venue/"+venue_id;
    
            axios.post( url, {
                _token: token,
            })
            .then(function (response) {

                $( "textarea[ name='address' ]" ).val( response.data.venue.address );
                $( "input[ name='postcode' ]" ).val( response.data.venue.post_code );
                $( "input[ name='contact_number' ]" ).val( response.data.venue.phone );
                $( "input[ name='contact_email' ]" ).val( response.data.venue.email );
    
                console.log( $( "textarea[ name='additional_info' ]" ).val() );

                // if additional info is currently blank overwrite with value from venue
                if ( "" == $( "textarea[ name='additional_info' ]" ).val() )
                    $( "textarea[ name='additional_info' ]" ).val( response.data.venue.additional_info );

                $( "textarea[ name='resources' ]" ).val( response.data.venue.resources );

                if ( venue_id == client_site_id )
                {
                    $("#sce_check").show();
                }
                else
                {
                    $("#sce_check").hide();
                }

            })
            .catch(function (error) {
                console.log( "Error:", error);
            });
        }   
    });

    function onFocus( e )
    {
        e.preventDefault();

        const $this = $( e.currentTarget );

        $this.data( 'before', $this.text() );

        // $this.addClass('editing');

    }

    function onFocusOut( e ) 
    {       
        // console.log( "onFocusOut", e );

        e.preventDefault();

        const $this = $(".editing").first();

        // const $this = $(this);

        $this.removeClass('editing');

        if ($this.data('before') !== $this.text()) {
            // $this.data('before', $this.text());
            $this.trigger('change');
            //$this.trigger('blur');
        }
    }


    function onBlur( e ) 
    {       
        // console.log( "onBlur", e );

        e.preventDefault();

        // const $this = $(this);

        // $this.removeClass('editing');

        // // if ($this.data('before') !== $this.html()) {
        // //     $this.data('before', $this.html());
        //     $this.trigger('change');
        //     $this.trigger('blur');
        // }

        const $this = $(".editing").first();

        // const $this = $(this);

        $this.removeClass('editing');

        if ($this.data('before') !== $this.text()) {
            // $this.data('before', $this.text());
            $this.trigger('change');
            //$this.trigger('blur');
        }

    }

    function onKeydown( e ) {

        const $this = $(this);

        let key = e.key || e.keyCode;
        
        var $editableDiv = $('.editable');
        
        if( key === 'Enter' || key === 13 )
        {

            e.preventDefault();

            // if ($this.data('before') !== $this.html()) {
            //     $this.data('before', $this.html());
                $this.trigger('change');
            // }
            
            $this.trigger('blur');

            var $index = $editableDiv.index(this),
                $next = $editableDiv.eq($index+1);
            if ( $next.length ) {
                // $next.focus();
                $next.trigger('focus');
                const $this = $next;
            } else {
                e.preventDefault();
                // $editableDiv.first().focus(); // cycle
            }
        }
      
        if ( !e.shiftKey && ( key === 'Tab' || key === 9 ) )
        {
            // if ($this.data('before') !== $this.html()) {
            //     $this.data('before', $this.html());
                $this.trigger('change');
            // }
            
            $this.trigger('blur');

            var $index = $editableDiv.index(this),
                $next = $editableDiv.eq($index+1);
            
            if ( $next.length ) {
                // $next.focus();
                $next.trigger( "focus" );
                const $this = $next;
            } else {
                e.preventDefault();
                // $editableDiv.first().focus(); // cycle
            }
            e.preventDefault();

        }

        if ( e.shiftKey && ( key === 'Tab' || key === 9 ) )
        {
            // if ($this.data('before') !== $this.html()) {
            //     $this.data('before', $this.html());
                $this.trigger('change');
            // }
            
            $this.trigger('blur');
            
            var $index = $editableDiv.index(this),
                $prev = $editableDiv.eq($index-1);

            if ( $index > 0 ) {
                $prev.focus();
                const $this = $prev;
                // $prev.trigger('focus');
            } else {
                e.preventDefault();
                // $editableDiv.first().focus(); // cycle
            }
            e.preventDefault();
        }

    }

    // $( '.editable' ).on( 'focus', '[contenteditable]', onFocus );
    
    $( '.editable' ).on( 'change', markDirty );

    function markDirty( e )
    {
        e.preventDefault();

        const $this = $(this);

        // if ($this.data('before') !== $this.text()) {
            $this.addClass( 'dirty' );
        // }

        let lid = $( e.currentTarget ).parent().parent().data( 'lid' );
        
        $( '#save-'+lid ).addClass("btn-danger");
    }

    // $( '.editable' ).on('keydown', onKeydown );
               
    // $( '.editable' ).on( 'blur paste ', '[contenteditable]', onBlur)
    //             .on( 'change', update_field );

    // $( '.editable' ).on( 'focus', '[contenteditable]', onFocus )
    //            .on( 'blur', '[contenteditable]', onFocusOut );

    // // $( '.editable' ).on( 'focus', onFocus )
    // //            .on( 'blur', onFocusOut );


    // $( 'body' ).on('mousedown', (e) => { 
    //     // console.log( 'Mousedown' ); 
        
    //     // console.log( document.activeElement.closest( "[contenteditable]" ));
    //     // console.log( document.activeElement.classList.contains( "editable" ));

    //     // only do this if we are in an editable field
    //     if ( document.activeElement.closest( "[contenteditable]" ) && document.activeElement.classList.contains( "editable" ) )
    //         $( '.editing' ).trigger( 'change' ); 
        
    //     return true; 
    // } ); 

    $( "#select-all" ).on( "click", function( e ) {
        var check_state = jQuery("#select-all").is(':checked');

        // console.log( $( "#select-all" ).is(':checked') );

        jQuery(".picker").each(function() {
            jQuery(this).prop("checked", check_state );
        });
    })


    $( "#block-mode" ).on( "click", function( e ) {

        e.preventDefault();

        blockEditMode = ( blockEditMode ? false : true );        

        if ( blockEditMode )
            $("#block-mode").addClass( 'btn-warning' );
        else
            $("#block-mode").removeClass( 'btn-warning' );

        console.log( "bEM", blockEditMode );
    })


    // $("#send-client-email").on( "click", function ( e ) {
    //     e.preventDefault();

    //     var contact_email = $( "input[ name='contact_email' ]" ).val();

    //     axios.post('/event/client-email', {
    //         _token: token,
    //         email: contact_email,

    //     })
    //     .then(function (response) {
    //     // console.log(response);
    //         // console.log( "selected emailed");

    //         toastr.success( "Joining Instructions Sent" );
    //     })
    //     .catch(function (error) {
    //         toastr.error( "There was an error sending email" );
    //         console.log(error);
    //     });

    // });


    $( ".add-new" ).on( "click", function( e ) {
        e.preventDefault();

        if ( $("#picker-new").length !== 0 )
        {
            console.log( $("#picker-new").length )
            return ;
        }

        var learner = {
            user_id: -1,
        };

        // var edit_route="http://127.0.0.1:8000";

        // $('#attendees-table').find('tbody').append( new_attendee_markup );
        $('#source_change').before( new_attendee_markup );

        window.attachHoverHandler();

        // re-bind the handlers
        
        // $( 'body' ).on( 'focus', '[contenteditable]', onFocus )
        //                 .on( 'blur paste ', '[contenteditable]', onBlur )
        //                 .on('keydown', '[contenteditable]', onKeydown );

        // $( '.editable' ).on( 'change', update_field );

        // $( '.editable' ).on('keydown', onKeydown );
               
        // $( '.editable' ).on( 'focus', '[contenteditable]', onFocus )
        //             .on( 'blur paste ', '[contenteditable]', onBlur)
        //             .on( 'change', update_field );
        
        // Gove the browser time to update the DOM
        // console.log( "binding handlers" );
    
        // $( '.editable' ).on('keydown', onKeydown );
    
        // $( '.editable' ).on( 'blur paste ', onBlur)
        //                 .on( 'change', update_field );
    
        // $( '.editable' ).on( 'focus', onFocus )
        //                 .on( 'blur', onFocusOut );

        $( '.deleteAttendee' ).on( 'click', deleteAttendee );

        $( '.rescheduleAttendee' ).on( 'click', rescheduleAttendee );

        $( '.passSelected' ).on( 'click', passSelected );

        $( '.passAttendee' ).on( 'click', passAttendee );

        $( '.sendJI' ).on( 'click', sendJI );

        $( '.viewCertificate' ).on( 'click', viewCertificate );

        $( '.save-button' ).on( 'click', update_fields );

        $( '.editable' ).on( 'change', markDirty );

    });

    $( "#email-selected" ).on( "click", function( e ) {
        e.preventDefault();

        let ids = jQuery(".picker:checked").map(function( e ) {
            return $( this ).data('lid') ;
        }).get();

        // console.log( ids );

        if ( ids.length > 0 )
        {
            // Send message
            var token = $('[name=_token]').val();

            axios.post('/email/attendees', {
                _token: token,
                ids: ids
            })
            .then(function (response) {
            // console.log(response);
                // console.log( "selected emailed");

                toastr.success( "Joining Instructions Sent" );
            })
            .catch(function (error) {
                toastr.error( "There was an error sending email" );
                console.log(error);
            });
        }
    });

    function passSelected( e ) {
        e.preventDefault();

        let ids = jQuery(".picker:checked").map(function( e ) {
            return $( this ).data('lid') ;
        }).get();

        // console.log( ids );

        if ( ids.length > 0 )
        {
            // Send message
            var token = $('[name=_token]').val();

            axios.post('/certificates/attendees', {
                _token: token,
                ids: ids
            })
            .then(function (response) {
            // console.log(response);
                // console.log( "selected passed");
                toastr.success( "Certificates sent to selected attendees" );
                
                ids.forEach( id => {
                    // console.log( id );
                    $( '#pass-'+id ).hide();
                    // $( '#certificate-'+id ).show();
                    // $( '#certificate-'+id ).on("click",  viewCertificate );
                });
            })
            .catch(function (error) {
                toastr.success( "There was a problem" );
                console.log(error);
            });
        }
    }

    $( "#pass-selected" ).on( "click", passSelected );

    function passAttendee( e ) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        let ids = [];

        let lid = $( e.currentTarget ).parent().parent().parent().data( 'lid' );
        
        // console.log( lid );

        ids.push( lid );

        // console.log( ids );

        if ( ids.length > 0 )
        {
            // Send message
            var token = $('[name=_token]').val();

            axios.post('/pass/attendees', {
                _token: token,
                ids: ids
            })
            .then(function (response) {
            // console.log(response);
                // console.log( "selected passed");
                toastr.success( "Marked as passed" );

                ids.forEach( id => {
                    // console.log( id );
                    $( '#pass-'+id ).hide();
                    $( '#certificate-'+id ).show();
                    $( '#certificate-'+id ).on("click",  viewCertificate );

                });

            })
            .catch(function (error) {
                toastr.error( "There was an error" );
                console.log(error);
            });
        }
    }

    $( ".passAttendee" ).on( "click", passAttendee );

    $( '.colour-btn' ).on('click', function( e )
    {
        $( '.colour-btn--selected' ).removeClass( 'colour-btn--selected' );

        let colour = $( e.currentTarget ).data( 'colour' );

        // console.log( colour );

        $( '#colour' ).val( colour );

        $( e.currentTarget ).addClass( 'colour-btn--selected' );

    } );

    $( '#chat-message' ).on('click', function( e )
    {
        e.preventDefault();
        $('#adminChatBox').modal( 'show' ); //modal();
    });

    $( '#duplicate-event' ).on('click', function( e )
    {
        console.log( "Duplicate Event", $('#duplicateEventBox') );

        e.preventDefault();
        $('#duplicateEventBox').modal( 'show' ); //modal();
    });

    $("[data-dismiss=modal]").on('click', function ( e ) {
        e.preventDefault();
        jQuery("#adminChatBox").modal( 'hide' );
        jQuery("#duplicateEventBox").modal( 'hide' );
        jQuery("#rescheduleAttendeeBox").modal( 'hide' );
        jQuery("#exportAttendeesBox").modal( 'hide' );
        jQuery("#repeatActionsBox").modal( 'hide' );

        jQuery("#trainersMapBox").modal( 'hide' );

    });

    $("#sendMessage").on('click', function ( e ) {
        e.preventDefault();
        jQuery("#adminChatBox").modal( 'hide' );

        // Send message
        var token = $('[name=_token]').val();
        var event_id = $('[name=id]').val();
        var chat_name = $('#chat_name').val();
        var chat_email = $('#chat_email').val();
        var chat_msge = $('#chat_msge').val();

        axios.post('/chat/send', {
            _token: token,
            event_id: event_id,
            name: chat_name,
            email: chat_email,
            message: chat_msge
          })
          .then(function (response) {
            // console.log(response);
            toastr.success( "Message Sent" );
          })
          .catch(function (error) {
            console.log(error);
          });

    });

    $("#duplicateEvent").on('click', function ( e ) {
        e.preventDefault();
        jQuery("#duplicateEventBox").modal( 'hide' );

        // Send message
        var token = $('[name=_token]').val();
        var event_id = $('[name=id]').val();
        var start_date = $('#start_date').val();
        
        axios.post('/event/duplicate/'+event_id, {
            _token: token,
            start_date: start_date
          })
          .then(function (response) {
            // console.log(response);
            toastr.success( "Course Duplicated" );
          })
          .catch(function (error) {
            toastr.error( "Course was not duplicated" );
            console.log(error);
          });

    });

    $( '.deleteAttendee' ).on( 'click', deleteAttendee );

    $( '.confirmTrainer').on( 'click', function ( e ) {

        e.preventDefault();

        let tid = $( e.currentTarget ).data( 'tid' );
        
        $('select[name="trainer"]').val( tid );

    });

    $( '.rescheduleAttendee' ).on( 'click', rescheduleAttendee );

    function rescheduleAttendee( e ) 
    {
        
        e.preventDefault();

        // let parent3Lid = $( e.currentTarget ).parent().parent().parent().data( 'lid' );
        let parent2Lid = $( e.currentTarget ).parent().parent().data( 'lid' );
        
        // console.log( parent2Lid, parent3Lid );

        // display the popup

        // 


        $( '#reschedule_lids').val( ""+parent2Lid );

        $( '#reschedule_date' ).val("");

        $('#reschedule_course').empty();

        $('#reschedule_course').append($('<option>',
        {
            value: 0,
            text: 'Available courses will display when a date is selected'
        }));

        $('#rescheduleAttendeeBox').modal( 'show' ); //modal();

    }

    $( '#reschedule-selected' ).on( 'click', rescheduleAttendees );

    function rescheduleAttendees( e )
    {
        
        e.preventDefault();

        // let parent3Lid = $( e.currentTarget ).parent().parent().parent().data( 'lid' );
        let parent2Lid = $( e.currentTarget ).parent().parent().data( 'lid' );
        
        var lids = "";
        var comma = "";

        $('.picker:checked').each( ( index, row ) => {

            let curLid = $(row).data( 'lid' );

            lids +=comma + curLid;

            comma = ",";
            
        });

        $( '#reschedule_lids').val( lids );

        $( '#reschedule_date' ).val("");

        $('#reschedule_course').empty();

        $( '#reschedule_course').append($('<option>',
        {
            value: 0,
            text: 'Available courses will display when a date is selected'
        }));

        $('#rescheduleAttendeeBox').modal( 'show' ); //modal();

    }
    

    $( '#performReschedule' ).on( 'click', performReschedule );

    function performReschedule( e )
    {
        e.preventDefault();

        var token = $('[name=_token]').val();
        
        var date = $( 'input[name="reschedule_date"]' ).val();

        var course = $( '#reschedule_course' ).val();

        var lids = $( '#reschedule_lids' ).val();

        $('#rescheduleAttendeeBox').modal( 'hide' );

        axios.post('/event/reschedule', {
            _token: token,
            date: date,
            course: course,
            lids: lids.split( "," )
          })
          .then(function (response) {
            // console.log(response);

            // foreach ( response.data.lids as lid )

            for( var i=0; i< response.data.lids.length; i++ )
            {
                // console.log( 'tr[data-lid="'+response.data.lids[i]+'"]' );
                $( 'tr[data-lid="'+response.data.lids[i]+'"]' ).remove( );
            }

          })
          .catch(function (error) {
            toastr.error( "There was a problem rescheduling attendees" );
            console.log(error);
          });


    }

    $("#reschedule_date").on("change", e => {
        // console.log( $( 'input[name="reschedule_date"]' ).val() );

        var date = $( 'input[name="reschedule_date"]' ).val();
        var token = $('[name=_token]').val();
        
        // alert( "Date changed" );

        axios.post('/event/dates', {
            _token: token,
            date: date
          })
          .then(function (response) {
            // console.log(response);

            $('#reschedule_course').empty();

            for( var i=0; i < response.data.events.length; i++ )
            {
                // console.log( response.data.events[i] );

                $('#reschedule_course').append($('<option>',
                {
                    value: response.data.events[i].id,
                    text: response.data.events[i].title+( response.data.events[i].venue != null ? ' - '+response.data.events[i].venue.name : '' ),
                }));
            }

            // console.log( "Success: now update the message list");
          })
          .catch(function (error) {
            console.log(error);
          });


    });

    $( "#sendJI" ).on( "click", function( e ) {
        e.preventDefault();

        // Send message
        var token = $('[name=_token]').val();
        var email = $('[name=sendjoiningemail]').val();
        var event_id = $('[name=id]').val();
        
        axios.post('/email/joining/'+event_id, {
            _token: token,
            email:email
        })
        .then(function (response) {
            toastr.success( "Joining Instructions Sent" );
        })
        .catch(function (error) {
            toastr.error( 'Error sending Joining Instructions');
            console.log(error);
        });

    });

    $( "#sendBE" ).on( "click", function( e ) {
        e.preventDefault();

        // Send message
        var token = $('[name=_token]').val();
        var email = $('[name=sendbookeremail]').val();
        var event_id = $('[name=id]').val();
        
        axios.post('/email/booker/'+event_id, {
            _token: token,
            email: email
        })
        .then(function (response) {
            toastr.success( "Course Details Sent" );
        })
        .catch(function (error) {
            toastr.error( 'Error sending Course Details');
            console.log(error);
        });

    });

    function sendJI( e ) {
        e.preventDefault();

        let parent2Lid = $( e.currentTarget ).parent().parent().data( 'lid' );

        var ids=[
            parent2Lid
        ];
        
        // Send message
        var token = $('[name=_token]').val();
        var email = $('[name=sendjoiningemail]').val();
        var event_id = $('[name=id]').val();
        
        axios.post('/email/joining/'+event_id, {
            _token: token,
            ids: ids
        })
        .then(function (response) {
            console.log( "JI sent");
            toastr.success( "Joining Instructions Sent" );
        })
        .catch(function (error) {
            toastr.success( "Error Sending Joining Instructions" );
            console.log(error);
        });

    }
    
    $( ".sendJI" ).on( "click", sendJI );

    function viewCertificate( e ) {
        // {{ route( 'certificate.generate', [ 'attendee' => $learner->id ] ) }}" target="_blank

        e.preventDefault();

        let lid = $( e.currentTarget ).parent().parent().data( 'lid' );

        window.open( "/certificate/generate/"+lid, "_blank" );

    }

    $(".viewCertificate").on("click",  viewCertificate );

    function convertToByteArray(input) {
        var sliceSize = 1024;
        var bytes = [];
      
        console.log( "input length", input.length );

        for (var offset = 0; offset < input.length; ) {
          var slice = input.slice(offset, offset + sliceSize);
      
          console.log( offset, slice.length );

          offset += slice.length;

          var byteNumbers = new Array(slice.length);
      
          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
      
          bytes.push(byteArray);
        }
      
        return bytes;
    }

    function save(filename, data) {

        const blob = new Blob( convertToByteArray( data ) , {type: 'application/vnd.ms-excel'});

        if ( window.navigator.msSaveOrOpenBlob ) 
        {
            console.log( "window.navigator" );

            window.navigator.msSaveBlob(blob, filename);
        }
        else 
        {
            console.log( "createObjectUrl" );

            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;        
            document.body.appendChild(elem);
            elem.click();        
            document.body.removeChild(elem);
        }
    }

    $( "#xls-export" ).on( "click", function( e ) {

        e.preventDefault();
        $('#exportAttendeesBox').modal( 'show' ); //modal();

    });

    $( "#performExport" ).on( "click", function( e ) {

        e.preventDefault();

        let ids = jQuery(".picker:checked").map(function( e ) {
            return $( this ).data('lid') ;
        }).get();

        if ( ids.length > 0 )
        {
            // Send message
            var token = $('[name=_token]').val();

            var path = "/export/attendees?_token="+token+"&ids="+ids.toString();

            var fields = [];

            if ( $("input[name='export-name']").is(":checked") )
                fields.push( 'name' );

            if ( $("input[name='export-email']").is(":checked") )
                fields.push( 'email' );

            if ( $("input[name='export-phone']").is(":checked") )
                fields.push( 'phone' );

            if ( $("input[name='export-cemail']").is(":checked") )
                fields.push( 'cemail' );

            if ( $("input[name='export-notes']").is(":checked") )
                fields.push( 'notes' );

            if ( $("input[name='export-certificate']").is(":checked") )
                fields.push( 'certificate' );


            path = path+"&fields="+fields.toString();

            $('#exportAttendeesBox').modal( 'hide' );
            
            // window.open( path, "_blank" );

            var link=document.createElement('a');
            document.body.appendChild( link );
            link.href=path;
            link.click();


        }
    });

    $('#repeated').on('change', function( e ) {
        
        // show or hide the repeat details

        let repeated = jQuery( "#repeated").is(":checked");

        if ( repeated )
        {
            jQuery( "#repeat_options").show();
        }
        else
        {
            jQuery( "#repeat_options").hide();
        }

    });


    $( "#product_id" ).on('change', ( e ) => {

        var selectedOption = $(e.currentTarget).find('option:selected');

        // console.log( "selcted", selectedOption, $( e.currentTarget ).find('option:selected')) ;

        var price = $( selectedOption ).data( 'price' );
        var sale_price = $( selectedOption ).data( 'sale-price' );


        var c_price = $( "input[ name='price_override']" ).val();
        var c_sale_price = $( "input[name='saleprice']" ).val();

        console.log( "prices", price, sale_price ); 
        console.log( "c_prices", c_price, c_sale_price ); 

        if ( c_price == 0 || c_price === "" )
        {
            $( "input[ name='price_override']" ).val( ""+price.toFixed(2) );
        }

        if ( c_sale_price == 0 || c_sale_price === ""  )
        {
            $( "input[ name='saleprice']" ).val( ""+sale_price.toFixed(2) );
        }

    });

    var targetNode = document.getElementById("admin_chat");
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
    var myObserver = new MutationObserver(mutationHandler);
    var obsConfig = {
      childList: false,
      characterData: true,
      attributes: true,
      subtree: false
    };
    
    //--- Add a target node to the observer. Can only add one node at a time.
    // targetNodes.each(function() {
    if ( targetNode != null )
      myObserver.observe( targetNode, obsConfig);
    // });
    
    function mutationHandler(mutationRecords) {
        setTimeout( () => 
        {
            // console.log($('.message_sender.selected.sender_name.span') )
            var selected_span = $('.selected').find('.sender_name' ).find('span');

            // console.log( selected_span );

            if ( selected_span.length > 0 && $(selected_span).is( ':visible' ) )
            {           
                var uid = $( selected_span ).data('uid');                
                var token = $('[name=_token]').val();
                var event_id = $('[name=id]').val();
                
                axios.post('/event/chat/viewed', {
                    _token: token,
                    event_id: event_id,
                    user_id: uid
                })
                .then(function (response) {
                // console.log(response);
                    $(selected_span).remove();

                    myObserver.disconnect();
                    })
                .catch(function (error) {
                    console.log(error);
                });
             
            }

        }, 3000);

    }

    $('.message_sender').on( 'click', (e) => {
        e.preventDefault();

        var sid = $( e.currentTarget ).data('sid');
        var token = $('[name=_token]').val();
        var event_id = $('[name=id]').val();

        console.log( "sid" );
        console.log( sid );

        // console.log( "click", sid );
        $('.message_sender.selected' ).removeClass( 'selected' );
        $( e.currentTarget ).addClass( 'selected ');
        
        setTimeout( () => {
            $('.selected').find('.sender_name' ).find('span').remove();            
        }, 3000);

        // get the chat history with the selected admin

        axios.post('/event/chat/'+sid, {
            _token: token,
            sid: sid,
            course_id: event_id,
          })
          .then(function (response) {
            console.log(response);


            $('#messages').empty();

            for( var i=0; i< response.data.messages.length; i++ )
            {
                // console.log( response.data.messages[i] );
                let message = response.data.messages[i];

                if (message.sender_id == sid )
                {
                    $('#messages').append('<div class="incoming_message">'+'<span style="font-size:0.75em;">'+message.sender_name+' wrote:</span><br />'+ message.chat_msge +'</div>' );
                }
                else
                {
                    $('#messages').append('<div class="outgoing_message">'+'<span style="font-size:0.75em;">You wrote:</span><br/>'+ message.chat_msge +'</div>' );
                }

            }

            // console.log( "Success: now update the message list");
          })
          .catch(function (error) {
            console.log(error);
          });

    });

    $('#send_message').on( 'click', (e) => {
        
        e.preventDefault();

        var message = $( '#chat_message' ).text();

        if ( message == "" )
            return ;

        var rid = $('.message_sender.selected' ).data('sid');
        
        console.log( "Recipient" );
        console.log( rid );

        var token = $('[name=_token]').val();
        // var event_id = $('[name=id]').val();
        var event_id = $('[name=id]').val();


        console.log( {
            _token: token,
            event_id: event_id,
            message: message,
            rid: rid
        } );


        // Assume the message has been successful
        $("#messages").append( '<div class="outgoing_message">'+message+'</div>' );
        
        $( '#chat_message' ).text("");     
        
        axios.post('/event/chat/send', {
            _token: token,
            event_id: event_id,
            message: message,
            rid: rid
        })
        .then(function (response) {
        // console.log(response);
            toastr.success( "Message was sent" );
        })
        .catch(function (error) {
            console.log(error);
            toastr.error( "Message could not be sent" );
        });
    
    });

    $( '#chat_message' ).on('keydown', ( e ) => {

        const $this = $(this);

        let key = e.key || e.keyCode;
        
        var $editableDiv = $('.editable');
        
        if( key === 'Enter' || key === 13 )
        {

            e.preventDefault();

            $('#send_message').trigger( "click" );

        }
      

    } );

});
