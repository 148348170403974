import jQuery from 'jquery';
window.$ = window.jQuery;

jQuery( function( $ )
{

    $( '.delete_employee' ).on( 'click' , function ( e ) {

        e.preventDefault();

        var admin_name = $( e.currentTarget ).data('name');

        console.log( e.CurrentTarget );

        console.log( this );

        console.log( "name", admin_name );

        let text = "Deleting Admin : "+admin_name+"\nAre you sure.\n\nTHIS PROCESS CANNOT BE UNDONE!";
        
        if (confirm(text) == true) {
         
          var tid = $( e.currentTarget ).data('id');
          // var uid = $( e.currentTarget ).data( 'uid' );
          
          // console.log( "delete trainer with id ", tid );
  
          var token = $('meta[name="csrf-token"]').attr('content');
  
          axios.post( route( 'employee.delete', { user: tid} ), {
              _token: token,
            })
            .then(function (response) {
              console.log(response);
              console.log( "Success: admin was deleted");
              
              toastr.success( "Admin was deleted");
              
              $( "#s"+tid ).remove();
  
            })
            .catch(function (error) {
              console.log(error);
              toastr.error( "Admin was not deleted");
            });
   
        }

    });

} );
