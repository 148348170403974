import jQuery from 'jquery';
window.$ = window.jQuery;

jQuery( function( $ )
{

    // console.log( route( 'trainer.apply', 1 ) );

    $( '.trainerApply' ).on( 'click' , function ( e ) {

        e.preventDefault();

        var uid = $( this ).data( 'uid' );
        var eid = $( this ).data( 'eid' );

        //console.log( uid, eid );

        console.log( route( 'trainer.apply', {user: uid, event: eid} ) );

        var token = $('meta[name="csrf-token"]').attr('content');

        axios.post( route( 'trainer.apply', {user: uid, event: eid} ), {
            _token: token,
          })
          .then(function (response) {
            // console.log(response);
            console.log( "Success: trainer has applied", toastr);
            toastr.success("Application Sent");

          })
          .catch(function (error) {
            console.log(error);
            toastr.error( "There was an error applying" );
          });

    });

    $( '.delete_trainer' ).on( 'click' , function ( e ) {

        e.preventDefault();

        var tid = $( e.currentTarget ).data('id');
        // var uid = $( e.currentTarget ).data( 'uid' );
        
        // console.log( "delete trainer with id ", tid );

        var token = $('meta[name="csrf-token"]').attr('content');

        axios.post( route( 'trainer.delete', { trainer: tid} ), {
            _token: token,
          })
          .then(function (response) {
            // console.log(response);
            // console.log( "Success: trainer was deleted");
            toastr.success( "Trainer was deleted");
            
            $( "#t"+tid ).remove();

          })
          .catch(function (error) {
            console.log(error);
            toastr.error( "Trainer was not deleted");
          });
 
    });

} );
