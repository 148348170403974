import jQuery from 'jquery';

import { first } from 'lodash';

window.$ = window.jQuery;


jQuery( function( $ )
{

    $( "#viewCertificate" ).on( "click", (e) => {

        var s_id = $( "input[ name='id' ]" ).val( );
            
        var win = window.open( "certificate/view/"+s_id, '_blank');

        if (win) {
            //Browser has allowed it to be opened
            win.focus();
        } else {
            //Browser has blocked it
            alert('Please allow popups for this website');
        }

    });

    $( "#deleteCertificate" ).on( "click", (e) => {

        var token = $('meta[name="csrf-token"]').attr('content');
        var s_id = $( "input[ name='id' ]" ).val( );
                           
        var url = "/sylabus/certificate/delete/"+s_id;

        console.log( url );

        axios.post( url, {
            _token: token,
        })
        .then(function (response) {
            console.log( "Success" );

            $( "input[ name='certificate' ]" ).val( "" );

            $( "#cert_upload" ).addClass('sylabus_show').removeClass('sylabus_hide');
            $( "#cert_manage" ).addClass('sylabus_hide').removeClass('sylabus_show');

        })
        .catch(function (error) {
            console.log( "Error:", error);
        });

    });

    $( '.delete_sylabus' ).on( 'click' , function ( e ) {

        e.preventDefault();

        var sid = $( e.currentTarget ).data('id');
        // var uid = $( e.currentTarget ).data( 'uid' );
        
        // console.log( "delete trainer with id ", tid );

        var token = $('meta[name="csrf-token"]').attr('content');

        axios.post( route( 'sylabii.delete', { sylabii: sid} ), {
            _token: token,
          })
          .then(function (response) {
            // console.log(response);
            // console.log( "Success: trainer was deleted");
            toastr.success( "Sylabus was deleted");
            
            $( "#s"+sid ).remove();

          })
          .catch(function (error) {
            console.log(error);
            toastr.error( "Sylabus was not deleted");
          });
 
    });

});