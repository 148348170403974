import jQuery from 'jquery';
window.$ = window.jQuery;

jQuery( function( $ )
{

    $('#trainersMap').on("click", (e ) => 
    {
        e.preventDefault();
        $('#trainersMapBox').modal( 'show' ); //modal();
    });

    // DISMISS CODE LOCATED IN EVENT.JS

    $("#send_all").on( "click", ( e ) => 
    {        
        var check_state = jQuery("#send_all").is(':checked');

        // console.log( "Select all" );

        jQuery( ".selector" ).each( (i, row ) =>
        {
            // console.log( "rows", i, row );
            
            jQuery(row).prop( "checked", check_state );
            // ts.prop( "checked", true );)
        });
    });

    $( "#notify_trainers" ).on( "click", ( e ) => {
        e.preventDefault();

        var start_value = "";

        let ids = jQuery(".selector:checked").map(function( e ) {
            return $( this ).data('tid') ;
        }).get();

        // console.log( "region", jQuery( "select[ name='region' ]").val() );
        // console.log( ids );

        var region = jQuery( "select[ name='region' ]").val();

        if ( ids.length > 0 )
        {
            // Send message
            var token = $('[name=_token]').val();

            axios.post('/trainers/notify_trainers', {
                _token: token,
                ids: ids,
                region: region,
            })
            .then(function (response) {
            // console.log(response);
                // console.log( "selected emailed");

                toastr.success( "Trainers Notified" );
            })
            .catch(function (error) {
                toastr.error( "There was an error sending email" );
                console.log(error);
            });
        }

    });

});