import jQuery from 'jquery';
window.$ = window.jQuery;


function attachHoverHandler( )
{
    $('.command-row').mouseover( function ( e )
    {
        $ ( e.currentTarget ).find( '.row-commands').css( "position", 'static' )
    });

    $('.command-row').mouseout( function ( e )
    {
        $ ( e.currentTarget ).find( '.row-commands').css( "position", 'relative' )
    });
}


jQuery( function( $ )
{
    window.attachHoverHandler = attachHoverHandler;
    attachHoverHandler();
});

// const elDivs = document.querySelectorAll(".command-row");

// const toggleClassDivs = (evt) => {

//     console.log( evt.type );

//     if ( evt.type == "mousenter" )
//     {
//         evt.currentTarget.classList.add( "show-commands" );
//     }
//     else
//     {
//         evt.currentTarget.classList.remove("show-commands");
//     }

// };

// elDivs.forEach(el => {
//   el.addEventListener("mouseenter", toggleClassDivs);
//   el.addEventListener("mouseleave", toggleClassDivs);
// });
